import { createApp } from 'vue';
import useLocale from '@/util/locale';
import router from '@/router';
import App from './App.vue';

const { i18n } = useLocale();

createApp(App)
	.use(router)
	.use(i18n)
	.mount('#app');
