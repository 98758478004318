import { computed, ref, Ref } from 'vue';
import { createI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import en from '@/locales/en.json';
import ar from '@/locales/ar.json';

import { LocaleItem } from '@/types';
import { getCookie, setCookie } from '@/util/cookies';

const arabicLocale: LocaleItem = {
	id: 'ar',
	title: 'العربية',
	order: 1
};

const englishLocale: LocaleItem = {
	id: 'en',
	title: 'English',
	order: 0
};

const supportedLocales: LocaleItem[] = [arabicLocale, englishLocale];

const messages = {
	en: {
		...en
	},
	ar: {
		...ar
	}
};

const LOCALE_COOKIE_NAME = 'disty_locale_pref';

const defaultLocale = arabicLocale;
const selectedLocaleId = ref(getCookie(LOCALE_COOKIE_NAME) || defaultLocale.id) as Ref<'en' | 'ar'>;

const i18n = createI18n({
	locale: selectedLocaleId.value,
	messages
});

export default function useLocale() {
	const router = useRouter();

	const selectedLocaleMessages = computed(() => i18n.global.messages[selectedLocaleId.value]);

	const onUpdate = (id: 'ar' | 'en', updateUrl = false) => {
		setCookie(LOCALE_COOKIE_NAME, id);
		selectedLocaleId.value = id;
		i18n.global.locale = id;
		if (!updateUrl) return;
		if (id === 'ar') {
			router.push({ path: `/ar`, replace: true });
			return;
		}
		router.push({ path: `/`, replace: true });
	};

	const onSwitch = () => {
		const id = selectedLocaleId.value === 'ar' ? 'en' : 'ar';
		onUpdate(id, true);
	};

	return {
		i18n,
		selectedLocaleMessages,
		selectedLocaleId,
		onUpdate,
		onSwitch
	};
}
