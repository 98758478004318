export const getCookie = (cookie: string) => {
  let name;
  let value;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    name = cookies[i].substr(0, cookies[i].indexOf('='));
    value = cookies[i].substr(cookies[i].indexOf('=') + 1);
    name = name.replace(/^\s+|\s+$/g, '');
    if (name === cookie) {
      return unescape(value);
    }
  }

  return null;
};

export const setCookie = (name: string, value: string, daysValid?: number, path = '/') => {
  const date = new Date();

  if (undefined !== daysValid) {
    date.setDate(date.getDate() + daysValid);
  }

  const cookie = escape(value) + (undefined === daysValid ? '' : (`; expires=${date.toUTCString()}`));
  document.cookie = `${name}=${cookie}; path=${path}`;
};
