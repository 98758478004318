import { createRouter, createWebHistory, Router } from 'vue-router';
import Routes from '@/router/appRoutes';
import useLocale from '@/util/locale';

const router: Router = createRouter({
	history: createWebHistory(process.env.VUE_APP_URL),
	routes: Routes,
	scrollBehavior(to) {
		if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth',
				top: 160
			};
		}

		return { top: 0 };
	}
});

router.beforeEach(async (to, from, next) => {
	if (to.path.startsWith('/ar')) {
		useLocale().onUpdate('ar');
		next();
	} else if (to.path.startsWith('/en')) {
		useLocale().onUpdate('en');
		next();
	} else {
		next({ path: `/${useLocale().selectedLocaleId.value}` });
	}
});

router.afterEach(async to => {
	document.title = String(to.name);
});

export default router;
