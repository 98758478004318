import { RouteRecordRaw } from 'vue-router';
import {
	HOME_PAGE_EN_ROUTE,
	HOME_PAGE_EN_NAME,
	HOME_PAGE_AR_ROUTE,
	HOME_PAGE_AR_NAME,
	PRIVACY_PAGE_EN_ROUTE,
	PRIVACY_PAGE_EN_NAME,
	PRIVACY_PAGE_AR_ROUTE,
	PRIVACY_PAGE_AR_NAME,
	TERMS_AND_CONDITIONS_PAGE_EN_ROUTE,
	TERMS_AND_CONDITIONS_PAGE_EN_NAME,
	TERMS_AND_CONDITIONS_PAGE_AR_ROUTE,
	TERMS_AND_CONDITIONS_PAGE_AR_NAME
} from '@/router/routes';

const Routes: Array<RouteRecordRaw> = [
	{
		path: HOME_PAGE_EN_ROUTE,
		name: HOME_PAGE_EN_NAME,
		component: () => import('@/views/Home.vue')
	},
	{
		path: HOME_PAGE_AR_ROUTE,
		name: HOME_PAGE_AR_NAME,
		component: () => import('@/views/Home.vue')
	},
	{
		path: PRIVACY_PAGE_EN_ROUTE,
		name: PRIVACY_PAGE_EN_NAME,
		component: () => import('@/views/Privacy_en.vue')
	},
	{
		path: PRIVACY_PAGE_AR_ROUTE,
		name: PRIVACY_PAGE_AR_NAME,
		component: () => import('@/views/Privacy_ar.vue')
	},
	{
		path: TERMS_AND_CONDITIONS_PAGE_EN_ROUTE,
		name: TERMS_AND_CONDITIONS_PAGE_EN_NAME,
		component: () => import('@/views/TermsAndConditions_en.vue')
	},
	{
		path: TERMS_AND_CONDITIONS_PAGE_AR_ROUTE,
		name: TERMS_AND_CONDITIONS_PAGE_AR_NAME,
		component: () => import('@/views/TermsAndConditions_ar.vue')
	}
];

export default Routes;
